import { TAG_IMAGE_DIFF, TAG_IMAGE_TYPE } from '~/constants';
import { getImageData } from './getImageData';
import { IMAGE_METADATA_TERMS } from '../constants/imageMetadataTerms';
import { isNumber } from 'lodash';

const { ADC, DIFF } = IMAGE_METADATA_TERMS;

export const createImageMetadata = (imageId: string) => {
  const imageData = getImageData(imageId);
  if (!imageData) return;

  const imageType: string | undefined = imageData.string(TAG_IMAGE_TYPE);
  if (!imageType) return;

  return {
    containsADC() {
      return imageType.includes(ADC);
    },
    containsDIFF() {
      return isNumber(this.getDIFF()) && !this.containsADC();
    },
    getDIFF(): number {
      return imageData.double(TAG_IMAGE_DIFF);
    },
    getDIFFLabel() {
      return DIFF;
    },
    getADCLabel() {
      return ADC;
    },
    imageData,
  };
};

import { GetState, SetState } from 'zustand';
import type { KeyMap, ApplicationKeyMap, ExtendedKeyMapOptions } from 'react-hotkeys';

export interface HotkeysStore {
  hotkeys: KeyMap;
  activeToolbarTool: string;

  setHotkeys: (hotkeys: ApplicationKeyMap) => void;
  // No replace existing fixed hotkeys
  setCustomHotkeys: (hotkeys: ApplicationKeyMap) => void;
  getHotkeys: () => KeyMap;
}

export enum HotkeyGroup {
  MEASUREMENT = 'MEASUREMENT',
  ROI = 'ROI',
  ANGLES = 'ANGLES',
  WWWC = 'WWWC',
  SPECIALTIES = 'SPECIALTIES',
  VISUALIZATION = 'VISUALIZATION',
  FLIP = 'FLIP',
  NAVIGATION = 'NAVIGATION',
  OTHER = 'OTHER',
}

export const hotkeysDefinition = {
  // Silent hotkeys
  DELETE_MEASUREMENT: {
    name: '',
    sequences: ['Delete', 'Backspace', 'Del', 'del', 'backspace', 'delete'],
    action: 'keydown',
  } as ExtendedKeyMapOptions,
  COPY_MEASUREMENT: {
    name: '',
    sequences: ['command+c', 'command+C', 'ctrl+c', 'ctrl+C'],
    action: 'keydown',
  } as ExtendedKeyMapOptions,
  PASTE_MEASUREMENT: {
    name: '',
    sequences: ['command+v', 'command+V', 'ctrl+v', 'ctrl+V'],
    action: 'keydown',
  } as ExtendedKeyMapOptions,
  // Active hotkeys
  // Measurement
  LENGHT: {
    name: 'viewer.subMenu.length',
    sequence: 'Shift+l',
    action: 'keydown',
    group: HotkeyGroup.MEASUREMENT,
  },
  BIDIRECTIONAL: {
    name: 'viewer.subMenu.bidirectional',
    sequence: 'Shift+b',
    action: 'keydown',
    group: HotkeyGroup.MEASUREMENT,
  },
  ICT: {
    name: 'viewer.toolbar.ctr',
    sequence: 'Shift+i',
    action: 'keydown',
    group: HotkeyGroup.MEASUREMENT,
  },
  DEVIATION: {
    name: 'viewer.toolbar.deviation',
    sequence: 'Shift+d',
    action: 'keydown',
    group: HotkeyGroup.MEASUREMENT,
  },
  // ROI
  ELLIPSE: {
    name: 'viewer.subMenu.ellipse',
    sequence: 'Shift+e',
    action: 'keydown',
    group: HotkeyGroup.ROI,
  },
  RECTANGLE: {
    name: 'viewer.subMenu.rectangle',
    sequence: 'Shift+q',
    action: 'keydown',
    group: HotkeyGroup.ROI,
  },
  SAMPLE: {
    name: 'viewer.subMenu.sample',
    sequence: 'Shift+m',
    action: 'keydown',
    group: HotkeyGroup.ROI,
  },
  STROKE: {
    name: 'viewer.subMenu.stroke',
    sequence: 'Shift+t',
    action: 'keydown',
    group: HotkeyGroup.ROI,
  },
  // Angles
  SIMPLE_ANGLE: {
    name: 'viewer.subMenu.newAngle',
    sequence: 'Shift+n',
    action: 'keydown',
    group: HotkeyGroup.ANGLES,
  },
  COBB_ANGLE: {
    name: 'viewer.subMenu.cobbAngle',
    sequence: 'Shift+c',
    action: 'keydown',
    group: HotkeyGroup.ANGLES,
  },
  GONIOMETRY: {
    name: 'viewer.subMenu.goniometry',
    sequence: 'Shift+g',
    action: 'keydown',
    group: HotkeyGroup.ANGLES,
  },
  COXOMETRY: {
    name: 'viewer.subMenu.coxometry',
    sequence: 'Shift+x',
    action: 'keydown',
    group: HotkeyGroup.ANGLES,
  },
  // WWWC
  PREDEFINED_LEVELS: {
    name: 'viewer.subMenu.levels',
    sequence: '1',
    action: 'keydown',
    group: HotkeyGroup.WWWC,
  },
  DINAMIC_RANGE: {
    name: 'viewer.subMenu.range',
    sequence: '2',
    action: 'keydown',
    group: HotkeyGroup.WWWC,
  },
  MEDIASTINO: {
    name: 'viewer.subMenu.mediastinum',
    sequence: '3',
    action: 'keydown',
    group: HotkeyGroup.WWWC,
  },
  PULMON: {
    name: 'viewer.subMenu.lung',
    sequence: '4',
    action: 'keydown',
    group: HotkeyGroup.WWWC,
  },
  HUESO: {
    name: 'viewer.subMenu.bone',
    sequence: '5',
    action: 'keydown',
    group: HotkeyGroup.WWWC,
  },
  CEREBRO: {
    name: 'viewer.subMenu.brain',
    sequence: '6',
    action: 'keydown',
    group: HotkeyGroup.WWWC,
  },
  CABEZA: {
    name: 'viewer.subMenu.head',
    sequence: '7',
    action: 'keydown',
    group: HotkeyGroup.WWWC,
  },
  ABDOMEN: {
    name: 'viewer.subMenu.abdomen',
    sequence: '8',
    action: 'keydown',
    group: HotkeyGroup.WWWC,
  },
  HIGADO: {
    name: 'viewer.subMenu.liver',
    sequence: '9',
    action: 'keydown',
    group: HotkeyGroup.WWWC,
  },
  WWWC: {
    name: 'WWWC',
    sequence: '0',
    action: 'keydown',
    group: HotkeyGroup.WWWC,
  },
  // Specialties
  MPR: {
    name: 'MPR',
    sequence: 'Shift+p',
    action: 'keydown',
    group: HotkeyGroup.SPECIALTIES,
  },
  View3D: {
    name: '3D',
    sequence: 'Shift+3',
    action: 'keydown',
    group: HotkeyGroup.SPECIALTIES,
  },
  // Visualization
  ZOOM_IN: {
    name: 'viewer.shortcuts.zoomI',
    sequence: '+',
    action: 'keydown',
    group: HotkeyGroup.VISUALIZATION,
  },
  ZOOM_OUT: {
    name: 'viewer.shortcuts.zoomO',
    sequence: '-',
    action: 'keydown',
    group: HotkeyGroup.VISUALIZATION,
  },
  ZOOM_TO_FIT: {
    name: 'viewer.shortcuts.zoomF',
    sequence: 'Shift+=',
    action: 'keydown',
    group: HotkeyGroup.VISUALIZATION,
  },
  ZOOM: {
    name: 'viewer.toolbar.zoom',
    sequence: 'z',
    action: 'keydown',
    group: HotkeyGroup.VISUALIZATION,
  },
  // Flip
  ROTATE_LEFT: {
    name: 'viewer.shortcuts.rotateL',
    sequence: 'l',
    action: 'keydown',
    group: HotkeyGroup.FLIP,
  },
  ROTATE_RIGHT: {
    name: 'viewer.shortcuts.rotateR',
    sequence: 'r',
    action: 'keydown',
    group: HotkeyGroup.FLIP,
  },
  FLIP_VERTICAL: {
    name: 'viewer.shortcuts.flipP',
    sequence: 'v',
    action: 'keydown',
    group: HotkeyGroup.FLIP,
  },
  FLIP_HORIZONTAL: {
    name: 'viewer.shortcuts.flipH',
    sequence: 'h',
    action: 'keydown',
    group: HotkeyGroup.FLIP,
  },
  // Navigation
  PREV_WINDOW: {
    name: 'viewer.shortcuts.previusWindow',
    sequence: 'left',
    action: 'keydown',
    group: HotkeyGroup.NAVIGATION,
  },
  NEXT_WINDOW: {
    name: 'viewer.shortcuts.nextWindow',
    sequence: 'n',
    action: 'keydown',
    group: HotkeyGroup.NAVIGATION,
  },
  PREV_IMAGE: {
    name: 'viewer.shortcuts.prevImage',
    sequence: 'up',
    action: 'keydown',
    group: HotkeyGroup.NAVIGATION,
  },
  NEXT_IMAGE: {
    name: 'viewer.shortcuts.nextImage',
    sequence: 'down',
    action: 'keydown',
    group: HotkeyGroup.NAVIGATION,
  },
  PREV_SERIE: {
    name: 'viewer.shortcuts.prevSerie',
    sequence: 'Shift+down',
    action: 'keydown',
    group: HotkeyGroup.NAVIGATION,
  },
  NEXT_SERIE: {
    name: 'viewer.shortcuts.nextSerie',
    sequence: 'Shift+up',
    action: 'keydown',
    group: HotkeyGroup.NAVIGATION,
  },
  // Others
  INVERT_COLORS: {
    name: 'viewer.shortcuts.invert',
    sequence: 'i',
    action: 'keydown',
    group: HotkeyGroup.OTHER,
  },
  UNDO: {
    name: 'viewer.shortcuts.restore',
    sequence: 'Shift+r',
    action: 'keydown',
    group: HotkeyGroup.OTHER,
  },
  CREATE_ANOTATION: {
    name: 'viewer.subMenu.newAnotation',
    sequence: 'Shift+a',
    action: 'keydown',
    group: HotkeyGroup.OTHER,
  },
};

const hotKeysStore = (set: SetState<HotkeysStore>, get: GetState<HotkeysStore>) => ({
  hotkeys: hotkeysDefinition,
  setHotkeys: (hotkeys) => {
    return set((state) => {
      state.hotkeys = hotkeys;
      return state;
    });
  },
  setCustomHotkeys: (hotkeys) => {
    const draftHotkeys = get().hotkeys;
    const fixedHotkeys = Object.keys(draftHotkeys)
      .filter((key) => !(draftHotkeys[key] as ExtendedKeyMapOptions)?.name)
      .reduce((cur, key) => {
        return Object.assign(cur, { [key]: draftHotkeys[key] });
      }, {});
    return set((state) => {
      state.hotkeys = { ...hotkeys, ...fixedHotkeys };
      return state;
    });
  },
  getHotkeys: () => {
    return get().hotkeys;
  },
});

export { hotKeysStore };

import { CalibrationTypes, US_MODALITY_REGION_TYPES } from '~/constants';
import isEqual from './isEqualWithTolerance';
import { conrnerstoneUnits, PHYSICAL_UNITS } from '@eva-pacs/core';
import csTools from 'cornerstone-tools';
import { isPointInRegion } from './isPointInRegion';
const getPixelSpacing = csTools.importInternal('util/getPixelSpacing');

/**
 * Enum representing the types of calibration.
 */
export enum CalibrationVariant {
  /**
   * Represents a symmetric calibration type (x and y axis have the same units).
   */
  SIMETRIC = 'SIMETRIC',

  /**
   * Represents an asymmetric calibration type (x and y axis have different units).
   */
  ASIMETRIC = 'ASIMETRIC',
}

/**
 * Interface representing the calibrated data for an image or dataset.
 */
interface CalibratedData {
  /**
   * Array of two cornerstone unit values. The first value corresponds to the X-axis,
   * and the second value corresponds to the Y-axis. These units represent the
   * measurement system used for calibration.
   */
  units: [conrnerstoneUnits, conrnerstoneUnits];

  /**
   * Array of two numbers representing the scaling factors used in the calibration.
   * The first scale applies to the X-axis, and the second scale applies to the Y-axis.
   */
  scales: [number, number];

  /**
   * The type of calibration applied, either symmetric or asymmetric, as defined
   * by the `CalibrationVariant` enum.
   */
  calibrationVariant: CalibrationVariant;
}

const SUPPORTED_REGION_DATA_TYPES = [
  US_MODALITY_REGION_TYPES.TISSUE,
  US_MODALITY_REGION_TYPES.COLOR_FLOW,
  US_MODALITY_REGION_TYPES.PW_SPECTRAL_DOPPLER,
];

const SUPPORTED_LENGTH_VARIANT = [
  `${PHYSICAL_UNITS.CENTIMETERS},${PHYSICAL_UNITS.CENTIMETERS}`, // x: cm  &  y:cm
  `${PHYSICAL_UNITS.SECONDS},${PHYSICAL_UNITS.CENTIMETERS__SECONDS}`, // x: cm  &  y:cm/sec
];

const SUPPORTED_PROBE_VARIANT = [
  `${PHYSICAL_UNITS.SECONDS},${PHYSICAL_UNITS.CENTIMETERS}`, // x: seconds  &  y : cm
];

const UNIT_MAPPING = {
  0: conrnerstoneUnits.pixelsComplete,
  1: conrnerstoneUnits.DEG,
  2: conrnerstoneUnits.dB,
  3: conrnerstoneUnits.CM,
  4: conrnerstoneUnits.SEC,
  5: conrnerstoneUnits.HERTZ,
  6: conrnerstoneUnits.dB_SEC,
  7: conrnerstoneUnits.CM_SEC,
  8: conrnerstoneUnits.CM2,
  9: conrnerstoneUnits.CM2_SEC,
  0xc: conrnerstoneUnits.DEG,
};

const EPS = 1e-3;
/**
 * Extracts the calibrated length units and the scale
 * for converting from internal spacing to image spacing.
 *
 * @param handles - to detect if spacing information is different between points
 * @param image - to extract the calibration from
 * @returns Object containing the units, and scale [ colPixelSpacing, rowPixelSpacing ]
 */

const getCalibratedLengthUnitsAndScale = (image, handles, calibration): CalibratedData => {
  let { rowPixelSpacing, colPixelSpacing } = getPixelSpacing(image);
  const hasPixelSpacing = rowPixelSpacing && colPixelSpacing;
  rowPixelSpacing = rowPixelSpacing || 1;
  colPixelSpacing = colPixelSpacing || 1;
  const defaultUnits = hasPixelSpacing ? conrnerstoneUnits.MM : conrnerstoneUnits.pixelsComplete;

  let units: [conrnerstoneUnits, conrnerstoneUnits] = [defaultUnits, defaultUnits]; // Default to pixels
  let scales: [number, number] = [colPixelSpacing, rowPixelSpacing]; //Scale in pixels
  // No calibration information
  if (
    !calibration ||
    (!calibration.type && !calibration.sequenceOfUltrasoundRegions) ||
    calibration.type === CalibrationTypes.UNCALIBRATED
  ) {
    units = hasPixelSpacing ? [conrnerstoneUnits.MM, conrnerstoneUnits.MM] : [defaultUnits, defaultUnits];
    return {
      units,
      scales: [colPixelSpacing, rowPixelSpacing],
      calibrationVariant: CalibrationVariant.SIMETRIC,
    };
  }
  if (calibration.sequenceOfUltrasoundRegions) {
    const { start, end } = handles;
    const startPoint: [number, number] = [start.x, start.y];
    const endPoint: [number, number] = [end.x, end.y];

    let regions = calibration.sequenceOfUltrasoundRegions.filter(
      (supportedRegion) => isPointInRegion(startPoint, supportedRegion) && isPointInRegion(endPoint, supportedRegion),
    );
    // If we are not in a region at all we should show the underlying calibration
    // which might be the mm spacing for the image
    if (!regions?.length) {
      return { units, scales, calibrationVariant: CalibrationVariant.SIMETRIC };
    }

    // if we are in a region then it is the question of whether we support it
    // or not. If we do not support it we should show px
    regions = regions.filter(
      (region) =>
        SUPPORTED_REGION_DATA_TYPES.includes(region.regionDataType) &&
        SUPPORTED_LENGTH_VARIANT.includes(`${region.physicalUnitsXDirection},${region.physicalUnitsYDirection}`),
    );
    // No supported regions for calibration
    if (!regions.length) return { units, scales, calibrationVariant: CalibrationVariant.SIMETRIC };

    // Todo: expand on this logic
    const region = regions[0];

    const physicalDeltaX = Math.abs(region.physicalDeltaX);
    const physicalDeltaY = Math.abs(region.physicalDeltaY);

    // if we are in a supported region then we should check if the
    // physicalDeltaX and physicalDeltaY are the same. If they are not
    // then we should show px again, but if they are the same then we should
    // show the units
    const isSamePhysicalDelta = isEqual(physicalDeltaX, physicalDeltaY, EPS);

    if (isSamePhysicalDelta) {
      // 1 to 1 aspect ratio, we use just one of them
      let imageUnits = UNIT_MAPPING[region.physicalUnitsXDirection] || 'unknown';
      let imagePhysicalDeltaX = physicalDeltaX;
      // The units are in (UNIT_MAPPING[3])cm, but for manteinance reasons we are using mm (all tools are in mm)
      if (imageUnits === UNIT_MAPPING[3]) {
        imageUnits = conrnerstoneUnits.MM;
        imagePhysicalDeltaX = physicalDeltaX * 10;
      }
      scales = [imagePhysicalDeltaX, imagePhysicalDeltaX];
      units = [imageUnits, imageUnits];
    } else {
      // Calibrating the image with different x and y spacing
      return {
        units: [
          UNIT_MAPPING[region.physicalUnitsXDirection] || 'unknown',
          UNIT_MAPPING[region.physicalUnitsYDirection] || 'unknown',
        ],
        scales: [physicalDeltaX, physicalDeltaY],
        calibrationVariant: CalibrationVariant.ASIMETRIC,
      };
    }
  } else if (calibration.scale) {
    scales = [calibration.scale, calibration.scale];
  }

  return {
    units,
    scales,
    calibrationVariant: CalibrationVariant.SIMETRIC,
  };
};

const getCalibratedProbeUnitsAndValue = (image, handles) => {
  const [probePoint] = handles;
  const { calibration } = image;
  let units = ['raw'];
  let values: Array<number> = [];
  let calibrationType = '';

  if (!calibration || (!calibration.type && !calibration.sequenceOfUltrasoundRegions)) {
    return { units, values };
    // Todo: add support for other scenarios
  }
  if (!calibration.sequenceOfUltrasoundRegions)
    return {
      units,
      values,
      calibrationType,
    };

  // for Probe tool
  const supportedRegionsMetadata = calibration.sequenceOfUltrasoundRegions.filter(
    (region) =>
      SUPPORTED_REGION_DATA_TYPES.includes(region.regionDataType) &&
      SUPPORTED_PROBE_VARIANT.includes(`${region.physicalUnitsXDirection},${region.physicalUnitsYDirection}`),
  );

  if (!supportedRegionsMetadata?.length) {
    return { units, values };
  }

  const region = supportedRegionsMetadata.find((supportedRegion) => isPointInRegion(probePoint, supportedRegion));

  if (!region) {
    return { units, values };
  }

  // Todo: I think this is a ok assumption for now that if the referencePixelX0 and referencePixelY0
  // are not defined, then we can assume 0 for them
  const { referencePixelX0 = 0, referencePixelY0 = 0 } = region;
  const { physicalDeltaX, physicalDeltaY } = region;

  const [x, y] = probePoint;
  const yValue = (y - region.regionLocationMinY0 - referencePixelY0) * physicalDeltaY;

  const xValue = (x - region.regionLocationMinX0 - referencePixelX0) * physicalDeltaX;

  calibrationType = 'US Region';
  values = [xValue, yValue];
  units = [UNIT_MAPPING[region.physicalUnitsXDirection], UNIT_MAPPING[region.physicalUnitsYDirection]];
  return {
    units,
    values,
    calibrationType,
  };
};

/** Gets the aspect ratio of the screen display relative to the image
 * display in order to square up measurement values.
 * That is, suppose the spacing on the image is 1, 0.5 (x,y spacing)
 * This is displayed at 1, 1 spacing on screen, then the
 * aspect value will be 1/0.5 = 2
 */
const getCalibratedAspect = (image) => image.calibration?.aspect || 1;

export { getCalibratedLengthUnitsAndScale, getCalibratedAspect, getCalibratedProbeUnitsAndValue };

import cornerstone from 'cornerstone-core';

import {
  LOSSY_IMAGE_COMPRESSION,
  DEFAULT_PRECISION_NUMBER,
  TAG_STUDY_LATERALITY,
  TAG_REPETITION_TIME,
  TAG_ECHO_TIME,
  TAG_STUDY_IMAGE_LATERALITY,
  TAG_COMPRESSION_FORCE,
  TAG_KVP,
  TAG_CURRENT,
  TAG_BODY_PART_THICKNESS,
  TAG_VIEW_POSITION,
} from '~/constants';
import { createImageMetadata } from '~/utils/createImageMetadata';
import { getImageData } from '~/utils/getImageData';

const getElectricCurrent = (imageId: string) => {
  const imageData = getImageData(imageId);
  if (!imageData) return;
  const current = imageData.intString(TAG_CURRENT) as string | undefined;
  return current;
};

const getKvp = (imageId: string) => {
  const imageData = getImageData(imageId);
  if (!imageData) return;
  const kvp = imageData.floatString(TAG_KVP) as string | undefined;
  return kvp;
};

const getCompressionForce = (imageId: string) => {
  const imageData = getImageData(imageId);
  if (!imageData) return;
  const force = imageData.floatString(TAG_COMPRESSION_FORCE) as string | undefined;
  return force;
};

const getBodyPartThickness = (imageId: string) => {
  const imageData = getImageData(imageId);
  if (!imageData) return;
  const bodyPartThickness = imageData.floatString(TAG_BODY_PART_THICKNESS) as string | undefined;
  return bodyPartThickness;
};

export const getADC = (imageId: string): string => {
  const imageMetadata = createImageMetadata(imageId);

  if (!imageMetadata?.containsADC()) return '';

  const ADCLabel = imageMetadata.getADCLabel();

  return ADCLabel;
};

export const getDIFF = (imageId: string): string => {
  const imageMetadata = createImageMetadata(imageId);

  if (!imageMetadata?.containsDIFF()) return '';

  const DIFF = imageMetadata.getDIFF();
  const DIFFLabel = imageMetadata.getDIFFLabel();

  return `${DIFFLabel}: ${DIFF}`;
};

const getStudyDescription = (imageId) => {
  const generalStudyModule = cornerstone.metaData.get('generalStudyModule', imageId) || {};
  const { dicomDescription } = generalStudyModule;

  return dicomDescription;
};

const getSeriesNumber = (imageId) => {
  const seriesMetadata = cornerstone.metaData.get('generalSeriesModule', imageId) || {};

  const { seriesNumber } = seriesMetadata;

  return seriesNumber >= 0 ? `Ser: ${seriesNumber}` : '';
};

function formatNumberPrecision(number, precision) {
  if (number !== null) {
    return parseFloat(number).toFixed(precision);
  }
}

const getZoom = (scale) => {
  return formatNumberPrecision(scale * 100, 0);
};

const getImageDimensions = (imageId) => {
  const imagePlaneModule = cornerstone.metaData.get('imagePlaneModule', imageId) || {};
  const { rows, columns } = imagePlaneModule;
  return rows && columns ? `${columns} x ${rows}` : '';
};

const getWwwc = (windowCenter: number, windowWidth: number) => {
  const maxParsedValueLength = 7;
  const parsedValue = (value: number) => value.toString().substring(0, maxParsedValueLength);
  return `W: ${parsedValue(windowWidth)} L: ${parsedValue(windowCenter)}`;
};

const getCompression = (imageId) => {
  const generalImageModule = cornerstone.metaData.get('generalImageModule', imageId) || {};
  const { lossyImageCompression, lossyImageCompressionRatio, lossyImageCompressionMethod } = generalImageModule;

  if (lossyImageCompression === LOSSY_IMAGE_COMPRESSION && lossyImageCompressionRatio !== '') {
    const compressionMethod = lossyImageCompressionMethod || 'Lossy: ';
    const compressionRatio = formatNumberPrecision(lossyImageCompressionRatio, 2);
    return compressionMethod + compressionRatio + ' : 1';
  }

  return 'Lossless / Uncompressed';
};

const getImageStackInfo = (imageId, imageIndex, stackSize) => {
  const generalImageModule = cornerstone.metaData.get('generalImageModule', imageId) || {};
  const { instanceNumber } = generalImageModule;
  return stackSize > 1 && instanceNumber ? `Img: ${instanceNumber} ${imageIndex}/${stackSize}` : '';
};

function isValidNumber(value) {
  return typeof value === 'number' && !isNaN(value);
}

const getThick = (imageId) => {
  const imagePlaneModule = cornerstone.metaData.get('imagePlaneModule', imageId) || {};
  const { sliceLocation, sliceThickness } = imagePlaneModule;
  return `${isValidNumber(sliceLocation) ? `Loc: ${formatNumberPrecision(sliceLocation, 2)} mm ` : ''} ${
    sliceThickness ? `Thick: ${formatNumberPrecision(sliceThickness, DEFAULT_PRECISION_NUMBER)} mm` : ''
  }`;
};

const getRepetitionAndEchoTime = (imageId) => {
  const viewportImage = cornerstone.imageCache.imageCache[imageId];
  if (!viewportImage) return '';
  let repetitionTime = viewportImage.image?.data?.string(TAG_REPETITION_TIME);
  let echoTime = viewportImage.image?.data?.string(TAG_ECHO_TIME);
  repetitionTime = repetitionTime
    ? Number(repetitionTime).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 })
    : '';
  echoTime = echoTime
    ? Number(echoTime).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 })
    : '';
  const labels: Array<string> = [];
  if (echoTime) labels.push(`TE: ${echoTime}`);
  if (repetitionTime && echoTime) labels.push(`/`);
  if (repetitionTime) labels.push(`TR: ${repetitionTime}`);
  return labels.join(' ');
};

const getLaterality = (imageId: string) => {
  const viewportImage = cornerstone.imageCache.imageCache[imageId];
  if (!viewportImage) return;

  const laterality = viewportImage.image?.data?.string(TAG_STUDY_LATERALITY) as string | undefined;
  const imageLaterality = viewportImage.image?.data?.string(TAG_STUDY_IMAGE_LATERALITY) as string | undefined;
  return laterality || imageLaterality;
};

const getViewPosition = (imageId: string) => {
  const viewportImage = cornerstone.imageCache.imageCache[imageId];
  if (!viewportImage) return;

  const viewPosition = viewportImage.image?.data?.string(TAG_VIEW_POSITION) as string | undefined;
  return viewPosition;
};

export {
  getElectricCurrent,
  getKvp,
  getCompressionForce,
  getBodyPartThickness,
  getStudyDescription,
  getSeriesNumber,
  getZoom,
  getImageDimensions,
  getWwwc,
  getCompression,
  getImageStackInfo,
  getThick,
  getLaterality,
  getViewPosition,
  getRepetitionAndEchoTime,
};
